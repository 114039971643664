import React, { useState, useEffect, useRef } from 'react';
import mapboxgl from '!mapbox-gl'; // eslint-disable-line import/no-webpack-loader-syntax
import baixa from './baixa.geojson';
import media from './media.geojson';
import alta from './alta.geojson';

mapboxgl.accessToken = 'pk.eyJ1IjoidGlkZWF3ZWIiLCJhIjoiY2t3Z3k2eGFpMHQ5dzJ2bnN6Z3Fsc3R4ZiJ9.8gMir8OiMwKczX2l6cqC7g';


function App() {
  const mapContainer = useRef(null);

  const [long, setLong] = useState(-43.2938453,12.98);
  const [lat, setLat] = useState(-22.9577649);
  const [zoom, setZoom] = useState(10);

  useEffect(() => {
    let map = new mapboxgl.Map({
      container: mapContainer.current,
      style: "mapbox://styles/mapbox/light-v10?optimize=true",
      center: [long, lat],
      zoom: zoom
    });

    map.once('load', () => {    


      map.addSource('areaBaixa', {
        'type': 'geojson',
        'data': baixa
      });

      map.addSource('areaMedia', {
        'type': 'geojson',
        'data': media
      });

      map.addSource('areaAlta', {
        'type': 'geojson',
        'data': alta
      });
   

      map.addLayer({
        'id': 'Alta',
        'type': 'fill',
        'source': 'areaAlta',
        'layout': {},
        'paint': {
            'fill-color': '#FF0000',
            'fill-opacity': [
                'case',
                ['boolean', ['feature-state', 'hover'], false],
                .8,
                0.5
            ]
        }        
      });


       map.addLayer({
        'id': 'Media',
        'type': 'fill',
        'source': 'areaMedia',
        'layout': {},
        'paint': {
            'fill-color':'#FFFF00',
            'fill-opacity': [
                'case',
                ['boolean', ['feature-state', 'hover'], false],
                .8,
                0.5
            ]
        }        
      });

      map.addLayer({
        'id': 'Baixa',
        'type': 'fill',
        'source': 'areaBaixa',
        'layout': {},
        'paint': {
            'fill-color': '#55FF00',
            'fill-opacity': [
                'case',
                ['boolean', ['feature-state', 'hover'], false],
                .8,
                0.5
            ]
        }        
      });

    });

  },[]);
  
  return (
   <div id="map" style={{flex: 1, backgroundColor: '#FFCC'}}>
      <div ref={mapContainer} className="map-container" style={{width: 'auto', height: '100vh'}} />
      <div id="legenda" style={{
        position: 'absolute',
        width: '60px',
        height: '100px',
        backgroundColor: '#FFFFFF',
        bottom: 40,
        left: 10,
        display: 'flex',
        alignItems: 'flex-start',
        justifyContent: 'center',
        paddingLeft: 10,
        flexDirection: 'column',
        fontSize: '12px',
      }}>
        <div style={{display: 'flex', alignItems: 'center', margin: '10px 0px', flexDirection: 'row'}}>
          <div style={{marginRight: 5, width: 10, height: 10, backgroundColor: '#FF0000'}}></div>
          <div>Alta</div>
        </div>
        <div style={{display: 'flex', alignItems: 'center', margin: '10px 0px', flexDirection: 'row'}}>
          <div style={{marginRight: 5, width: 10, height: 10, backgroundColor: '#FFFF00'}}></div>
          <div>Média</div>
        </div>
        <div style={{display: 'flex', alignItems: 'center', margin: '10px 0px', flexDirection: 'row'}}>
          <div style={{marginRight: 5, width: 10, height: 10, backgroundColor: '#55FF00'}}></div>
          <div>Biaxa</div>
        </div>

      </div>
   </div>
 
  );
}

export default App;
 